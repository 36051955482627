@import "../bower/bootstrap/less/bootstrap.less";
//@import "../bower/fuelux/less/fuelux.less";
@import "variables.less";
@import "fonts.less";

.scrollup {
  position: fixed;
  width: 32px;
  height: 32px;
  bottom: 0px;
  right: 20px;
  background: #222;
  color: #fff;
}

a.scrollup {
  outline: 0;
  text-align: center;
  line-height: 32px;
  color: #fff;
}
