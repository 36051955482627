@import 'base';

/*
  * Previously "styles-fullnav.css"
  */

body {
	font-family: 'Open Sans', sans-serif;
	color: #666;
}

a {
	color: #115062;
}

h1, h2, h3, h4, h6, .h1, .h2, .h3, .h4, .h6 {
	color: #484848;
	font-weight: 700;
	letter-spacing: -1px;
}

h4 {
	line-height: 25px;
}

h5, .h5 {
	color: #484848;
	font-weight: 700;
	letter-spacing: 0;
}

h2 {
	font-size: 30px;
	margin-top: 20px;
	margin-bottom: 10px;
}

mark {
	background: #fff5d9;
}

/* header */

.form-group hr.clearfix {
	margin-bottom: 0;
}

.err_msg {
	color: red;
}

.testimonialPopupLink {
	cursor: pointer;
}

.testimonialPopup-modal .modal-content {
	border: 2px solid #518400;
	border-color: rgba(81, 132, 0, 1);
	background-color: #80d700;
	background-color: rgba(128, 215, 0, 1);
	color: #333;
}

.testimonialPopupWrapper a {
	color: #FFF;
	text-decoration: none;
}

.testimonialPopupWrapper a:hover {
	text-decoration: none;
	color: #484848;
}

.testimonialPopupWrapper h1 {
	font-size: 28px;
	color: #484848;
	font-weight: bold;
	margin: 0px;
	padding: 0px;

}

.testimonialPopupWrapper h4 {
	font-size: 17px;
	color: #484848;
	font-weight: normal;
	margin: 0px;
	padding: 0px;

}

.testimonialPopupWrapper h5 {
	font-size: 15px;
	color: #FFF;
	font-weight: normal;
	margin: 0px;
	padding: 0px;

}

.testimonialPopupWrapper .poupcontainer {
	margin: 0px;
	padding: 15px;
	width: 100%;
}

.testimonialPopupWrapper .aligncenter {
	text-align: center;
}

.testimonialPopupWrapper .well.greenbg {
	background-color: #64A600 !important;
	padding: 10px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
}

.testimonialPopupWrapper .greentxt {
	color: #64A600;
}

.testimonialPopupWrapper .whitetxt {
	color: #ffffff;
}

.form-group small.text-muted {
	white-space: nowrap;
}

.help-block.help-block-red {
	color: red;
}

/* callus */
.callus {
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	font-size: 28px;
	color: #484848;
	float: right;
	display: block;
	clear: both;
	padding-top: 5px;
	line-height: 31px;
	letter-spacing: -1px;
	height: 31px;
}

.callus span {
	color: #D2A42E;
	font-weight: 800;
}

.callus img {
	margin-bottom: 4px;
	margin-left: 5px;
}

/* navbar */
.navbar-inverse {
	background-color: #fff;
	border: none;
	background: #fff url(/images/bg-top.png) repeat-x;
}

.navbox {
	display: block;
	clear: both;
	margin-top: 0;
}

.navbar-collapse {
	background-color: transparent;
}

.navbar-inverse .navbar-nav > li > a {
	letter-spacing: -1px;
}

.navbar-inverse .navbar-nav > li > a:hover {
	color: #666;
	background-color: transparent;
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
	border-color: transparent;
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: none;
}

.nav {
	font-weight: 700;
	margin-top: 10px;
}

.navbar {
	margin-bottom: 0;
}

.nav > li > a {
	padding: 5px 8px;
}

.nav.topmenu > li > a {
	padding: 5px 5px;
}

.navbar-inverse .navbar-nav > li > a {
	text-transform: uppercase;
	color: #484848;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
	color: #D2A42E;
	background-color: transparent;
}

//.navbar-nav.navbar-right li {
//  border-right: solid 1px #ccc;
//}
//
//.navbar-nav.navbar-right li:last-child {
//  border-right: none;
//}

.navbar-inverse .navbar-toggle {
	border-color: #145165;
}

.navbar-toggle {
	background-color: #145165;
	border: 1px solid transparent;
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
	background-color: #0F3E4D;
}

/* jumbotron */
.jumbotron {
	padding: 0;
	margin-bottom: 0;
	color: #fff;
	background-color: #115062;
	background: #115062 url(/images/bg-jumbo.png) repeat-x top left;
}

.jumbotron .container {
	position: relative;
}

#inners.jumbotron {
	padding: 50px 0;
	color: #fff;
	background-color: #115062;
	background: #115062 url(/images/bg-jumbo.png) repeat-x top left;
}

#inners.jumbotron .container {
	position: relative;
}

#statelanding.jumbotron {
	padding: 10px;
	color: #fff;
	background: #115062 url(/images/bg-jumbo-state.png) repeat-x top left;
	border-radius: 6px;
	position: relative;
}

.jumbotron h1, .jumbotron .h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
	letter-spacing: -1px;
}

#inners.jumbotron h5, #inners.jumbotron .h5 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: #fff;
}

#statelanding.jumbotron h1, #statelanding.jumbotron .h1 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
	letter-spacing: -1px;
	color: #D2A42E;
}

.jumbotron h2, .jumbotron .h2 {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	letter-spacing: -1px;
	color: #D2A42E;
}

.jumbotron h3, .jumbotron .h3 {
	font-weight: 800;
	color: #115062;
	margin-top: 0;
	margin-bottom: 10px;
}

.jumbotron h4, .jumbotron .h4 {
	font-weight: 400;
	font-weight: normal;
	color: #fff;
	letter-spacing: 0;
}

.jumbotron .well {
	padding: 12px;
	margin-bottom: 20px;
	margin-top: 40px;
	background-color: rgba(0, 0, 0, .2);
	border: none;
	border-radius: 6px;
	-webkit-box-shadow: none;
	box-shadow: none;
	font-size: 20px;
	min-height: 140px;
	font-weight: 400;
}

.jumbotron .well a {
	font-size: 16px;
	color: #D2A42E;
}

.jumbotron .standard-text p {
	font-size: 14px;
	line-height: 1.42857143;
}

.container .jumbotron {
	border-radius: 0;
}

.homeforms {
	color: #041D33;
	font-size: 13px;
}

.homeforms a {
	color: #114D5F;
}

.stateforms {
	color: #041D33;
	font-size: 13px;
}

.stateforms a {
	color: #114D5F;
}

#statelanding .form.stateforms h3 {
	font-size: 22px;
	font-weight: 800;
	line-height: 26px;
	letter-spacing: -1px;
	text-transform: capitalize;
	text-align: center;
	margin-top: 0;
}

#statelanding .form.stateforms h3 span {
	font-weight: 800;
	letter-spacing: -1px;
	color: #484848;
}

.input-sm, .form-horizontal .form-group-sm .form-control {
	height: 28px;
	padding: 5px 5px;
	font-size: 12px;
	line-height: 1.4;
}

select.input-sm {
	height: 28px;
	line-height: 28px;
}

.form-group {
	margin-bottom: 8px;
}

.help-block {
	display: block;
	margin-top: 0;
	margin-bottom: 0;
	color: #737373;
	font-size: 11px;
	text-align: right;
}

.btn-success {
	font-weight: 800;
	font-size: 22px;
	padding: 0;
	color: #fff;
	background-color: transparent;
	border: none;
	background: url(/images/bg-button.png) no-repeat left 3px;
	min-width: 248px;
	min-height: 56px;
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
	color: #484848;
	background-color: transparent;
	border: none;
}

.btn-start {
	font-size: 30px;
}

.btn-start:hover {
	background-color: #ad8726;
}

.paddright5 {
	padding-right: 5px !important;
}

.paddleft5 {
	padding-left: 5px !important;
}

.reviewbox {
	margin-top: 30px;
}

/* footer */
.footerscss {
	background-color: #ddd;
	text-align: center;
	margin-top: 30px;
	padding-top: 20px;
	padding-bottom: 10px;
	width: 100%;
	font-size: 12px;
}

.footerscss p {
	padding-left: 15px;
	padding-right: 15px;
}

/* seal */
.seals {
	z-index: 990;
	position: absolute;
	padding: 0;
}

#inners .seals {
	z-index: 990;
	position: absolute;
	padding: 0;
}

/* arrow - statelanding */
.arrows {
	z-index: 995;
	position: absolute;
	padding: 0;
	top: -12px;
	right: 311px;
	left: auto;
	width: 150px;
	height: 90px;
}

.lead {
	font-size: 20px;
	color: #484848;
	font-weight: 700;
}

.my-divorce-steps li h4 {
	color: #FFF;
}

.centered { margin: 0 auto; text-align: center; }

.section-image {
	-webkit-box-shadow: 0px 10px 20px -7px rgba(0, 0, 0, 0.8);
	-moz-box-shadow: 0px 10px 20px -7px rgba(0, 0, 0, 0.8);
	box-shadow: 0px 10px 20px -7px rgba(0, 0, 0, 0.8);
}

.pricing-header {
	margin-bottom: 50px;
}

.pricing-header h3 {
	color: #114F63;
}

.common-questions h1 {
	color: #115062;
}

.common-questions h4 {
	text-align: justify;
}
.manbanner h3 { color: #FFF !important; }

@media (max-width: 480px) {

	.pricing-table tbody tr td .glyphicon-ok {
		padding-right: 0px !important;
	}

}

@media (min-width: 768px) {

	ul.nav li.dropdown:hover > ul.dropdown-menu {
		display: block;
	}

}

@media (min-width: 1200px) {
	.postbox {
		height: 460px;
	}

	#statelanding .form.stateforms h3 span {
		font-size: 34px;
		line-height: 32px;
		display: block;
	}

	.wellsmcc {
		min-height: 140px;
	}

	.wellsmgua {
		min-height: 140px;
		padding-top: 20px;
	}

	.wellsmfree {
		min-height: 140px;
		padding-top: 30px;
	}

	.well.video h4 {
		font-size: 22px;
	}

	#attorney.well {
		background: #D2A42E url(/images/pic-attorney2.png) left bottom no-repeat;
		padding: 15px 15px 10px 100px !important;
		font-size: 14px;
	}

	#attorney.well h2 {
		font-size: 30px;
	}

	#attorney.well h2 span {
		font-size: 24px;
	}

	h4.modal-title {
		font-size: 23px;
	}

	#summforms h3 small {
		display: inline-block;
		padding-bottom: 0;
		padding-top: 5px;
		float: right;
	}

	.trlarge {
		text-align: right;
	}

	.well.livechat {
		float: left;
	}

	.rightcol {
		margin-top: 20px;
	}

	.breadcrumb {
		margin-top: 10px;
	}

	/* seal */
	.seals {
		top: 0;
		width: 180px;
		height: 170px;
		right: 0;
	}

	#inners .seals {
		top: -50px;
		width: 158px;
		height: 150px;
		right: 5px;
	}

	.paybanner h3 {
		margin-top: 30px;
		margin-left: 200px;
		font-size: 34px;
	}

	.manbanner h3 {
		margin-top: 10px;
		margin-left: 200px;
		font-size: 34px;
	}

	.well.printbanner {
		background: #D2A42E url(/images/print-img.png) no-repeat left top;
		height: 250px;
	}

	.printbanner h3 {
		margin-top: 40px;
		margin-left: 240px;
		font-size: 28px;
	}

	.printbanner h4 {
		margin-top: 30px;
		margin-left: 240px;
		font-size: 18px;
	}

	.well.pricebanner {
		background: #D2A42E url(/images/price-hdr.png) no-repeat left top;
		height: 250px;
	}

	.pricebanner h3 {
		margin-top: 10px;
		margin-left: 240px;
		font-size: 28px;
	}

	.pricebanner h4 {
		margin-top: 15px;
		margin-left: 240px;
		font-size: 18px;
	}

	h4.receipttitle {
		line-height: 1.3;
		font-size: 18px;
	}

	.jumbotron h1, .jumbotron .h1 {
		font-size: 56px;
		margin-bottom: 5px;
	}

	#statelanding.jumbotron h1, #statelanding.jumbotron .h1 {
		font-size: 35px;
		margin-bottom: 5px;
		margin-top: 10px;
	}

	#inners.jumbotron h1, #inners.jumbotron .h1 {
		font-size: 35px;
		margin-bottom: 5px;
		margin-right: 160px;
	}

	#inners.jumbotron h5, #inners.jumbotron .h5 {
		font-size: 14px;
		margin-top: 5px;
		margin-right: 160px;
	}

	#custominnerpagecontent h5 {
		line-height: 17px;
	}

	.jumbotron h2, .jumbotron .h2 {
		font-size: 27px;
		margin-top: 0;
		margin-bottom: 5px;
		text-align: right;
		margin-right: 200px;
	}

	.jumbotron h3, .jumbotron .h3 {
		font-size: 30px;
		margin-bottom: 5px;
	}

	.jumbotron h4, .jumbotron .h4 {
		font-size: 18px;
		margin-top: 0;
		margin-right: 200px;
		margin-left: 0;
		text-align: right;
	}

	.homeforms {
		background-image: url(/images/bg-form.png);
		background-repeat: no-repeat;
		background-position: right top;
		background-color: transparent;
		padding: 80px 40px 38px 30px;
		border-radius: 0;
		margin-top: 0;
		margin-right: 0;
		margin-left: 0;
		margin-bottom: 0;
	}

	#hpanel.homeforms {
		padding: 80px 40px 38px 30px;
		min-height: 475px;
	}

	.stateforms {
		background-image: url(/images/bg-top.png);
		background-repeat: repeat-x;
		background-position: top;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 6px;
		margin-top: 10px;
		margin-right: 15px;
		margin-left: 15px;
		margin-bottom: 10px;
	}

	.jumbocheck {
		margin-top: 30px;
		padding: 0;
	}

	.jumbocheck ul {
		list-style: none;
		list-style-image: none;
		font-size: 22px;
		font-weight: 700;
		padding: 0;
		margin: 0;
		//background: url(/images/seal-ca.png) no-repeat right bottom;
		min-height: 170px;
	}

	.jumbocheck ul li {
		background: url(/images/ico-check.png) left no-repeat;
		padding-left: 25px;
	}

	.reviewbox {
		margin-right: 15px;
		margin-left: 15px;
	}

	#stepforms h3,
	#summforms h3,
	#frmreferral h3 {
		font-size: 24px;
	}

	#receiptform h3 {
		font-size: 24px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.postbox {
		height: 450px;
	}

	#statelanding .form.stateforms h3 span {
		font-size: 22px;
		line-height: 22px;
		display: block;
	}

	.wellsmcc {
		min-height: 170px;
		padding-top: 10px;
	}

	.wellsmgua {
		min-height: 170px;
		padding-top: 15px;
	}

	.wellsmfree {
		min-height: 170px;
		padding-top: 30px;
	}

	.well.video h4 {
		font-size: 18px;
	}

	#attorney.well {
		background: #D2A42E url(/images/pic-attorney2.png) left bottom no-repeat;
		padding: 15px 15px 10px 100px !important;
		font-size: 13px;
	}

	#attorney.well h2 {
		font-size: 26px;
	}

	#attorney.well h2 span {
		font-size: 20px;
	}

	h4.modal-title {
		font-size: 22px;
	}

	#summforms h3 small {
		display: inline-block;
		padding-bottom: 0;
		padding-top: 5px;
		float: right;
	}

	.trlarge {
		text-align: right;
	}

	.well.livechat {
		float: left;
	}

	.rightcol {
		margin-top: 20px;
	}

	.breadcrumb {
		margin-top: 5px;
	}

	/* seal */
	.seals {
		top: 1px;
		width: 158px;
		height: 150px;
		right: 0;
	}

	#inners .seals {
		top: -49px;
		width: 158px;
		height: 150px;
		right: 0;
	}

	.paybanner h3 {
		margin-top: 33px;
		margin-left: 200px;
		font-size: 28px;
	}

	.manbanner h3 {
		margin-top: 15px;
		margin-left: 200px;
		font-size: 28px;
	}

	.well.printbanner {
		background: #D2A42E url(/images/print-img.png) no-repeat left top;
		height: 250px;
	}

	.printbanner h3 {
		margin-top: 50px;
		margin-left: 220px;
		font-size: 23px;
	}

	.printbanner h4 {
		margin-top: 20px;
		margin-left: 220px;
		font-size: 16px;
	}

	.well.pricebanner {
		background: #D2A42E url(/images/price-hdr.png) no-repeat left top;
		height: 250px;
	}

	.pricebanner h3 {
		margin-top: 10px;
		margin-left: 220px;
		font-size: 23px;
	}

	.pricebanner h4 {
		margin-top: 20px;
		margin-left: 220px;
		font-size: 15px;
	}

	h4.receipttitle {
		line-height: 1.3;
		font-size: 16px;
	}

	.jumbotron h1, .jumbotron .h1 {
		font-size: 46px;
		margin-bottom: 5px;
		margin-left: 0;
	}

	#statelanding.jumbotron h1, #statelanding.jumbotron .h1 {
		font-size: 28px;
		margin-bottom: 5px;
		margin-left: 0;
		margin-top: 10px;

	}

	#inners.jumbotron h1, #inners.jumbotron .h1 {
		font-size: 28px;
		margin-bottom: 5px;
		margin-right: 140px;
	}

	#inners.jumbotron h5, #inners.jumbotron .h5 {
		font-size: 14px;
		margin-top: 5px;
		margin-right: 140px;
	}

	.jumbotron h2, .jumbotron .h2 {
		font-size: 23px;
		margin-top: 0;
		margin-bottom: 5px;
		margin-left: 0;
	}

	.jumbotron h3, .jumbotron .h3 {
		font-size: 28px;
	}

	.jumbotron h4, .jumbotron .h4 {
		font-size: 15px;
		margin-top: 0;
		margin-right: 175px;
		margin-left: 0;
		text-align: right;
	}

	.homeforms {
		background-image: url(/images/bg-top.png);
		background-repeat: repeat-x;
		background-position: top;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 6px;
		margin-top: 10px;
		margin-right: 15px;
		margin-left: 15px;
		margin-bottom: 10px;
	}

	#hpanel.homeforms {
		padding: 10px 10px 0 10px;
	}

	.stateforms {
		background-image: url(/images/bg-top.png);
		background-repeat: repeat-x;
		background-position: top;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 6px;
		margin-top: 10px;
		margin-right: 15px;
		margin-left: 15px;
		margin-bottom: 10px;
	}

	.jumbocheck {
		margin-top: 10px;
		padding: 0;
	}

	.jumbocheck ul {
		list-style: none;
		list-style-image: none;
		font-size: 18px;
		font-weight: 700;
		padding: 0;
		margin: 0;
		//background: url(/images/seal-ca2.png) no-repeat right 30px;
		min-height: 120px;
	}

	.jumbocheck ul li {
		background: url(/images/ico-check.png) left no-repeat;
		padding-left: 25px;
	}

	.reviewbox {
		margin-right: 15px;
		margin-left: 15px;
	}

	#stepforms h3,
	#summforms h3,
	#frmreferral h3 {
		font-size: 24px;
	}

	#receiptform h3 {
		font-size: 24px;
	}
}

@media (min-width: 992px) {
	.nav {
		font-size: 15px;
	}

	.navbar-inverse {
		height: 120px;
	}

	#header-logo {
		background: url(/images/logo.png) no-repeat 0 15px;
		width: 312px;
		height: 75px;
		display: block;
		text-indent: -9999px;
	}
}
@media (min-width: 1px) and (max-width: 600px) {
	.btn-start {
		font-size: 18px;
	}
	.common-questions img {
		width: 50%;
		margin: 0 auto;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.nav {
		font-size: 13px;
	}

	.postbox img {
		float: left;
		max-width: 200px;
		margin-right: 5px;
	}

	.postbox {
		height: auto;
	}

	#statelanding .form.stateforms h3 span {
		font-size: 22px;
		line-height: 22px;
		display: inline;
	}

	.wellsmcc {
		min-height: 170px;
		padding-top: 25px;
	}

	.wellsmgua {
		min-height: 170px;
		padding-top: 15px;
	}

	.wellsmfree {
		min-height: 170px;
		padding-top: 30px;
	}

	.well.video h4 {
		font-size: 22px;
	}

	#attorney.well {
		background: #D2A42E url(/images/pic-attorney3.png) left bottom no-repeat;
		padding: 15px 15px 10px 100px !important;
		font-size: 14px;
	}

	#attorney.well h2 {
		font-size: 28px;
	}

	#attorney.well h2 span {
		font-size: 28px;
	}

	h4.modal-title {
		font-size: 20px;
	}

	#summforms h3 small {
		display: block;
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.trlarge {
		text-align: right;
	}

	.well.livechat {
		float: right;
	}

	.rightcol {
		margin-top: 0;
	}

	.breadcrumb {
		margin-top: 15px;
	}

	.navbar-inverse {
		height: 100px;
	}

	#header-logo {
		margin: 0;
		background: url(/images/logo2.png) no-repeat 5px 15px;
		width: 239px;
		height: 60px;
		display: block;
		text-indent: -9999px;
	}

	/* callus */
	.callus {
		font-size: 20px;
		float: right;
		display: block;
		clear: both;
		padding-top: 5px;
		line-height: 31px;
		height: 31px;
	}

	.callus span {
		color: #D2A42E;
		font-weight: 800;
	}

	.callus img {
		margin-bottom: 4px;
		margin-left: 5px;
	}

	/* seal */
	.seals {
		top: 3px;
		width: 137px;
		height: 130px;
		left: 0;
	}

	#inners .seals {
		top: -47px;
		width: 116px;
		height: 110px;
		right: 5px;
		left: auto;
	}

	.paybanner h3 {
		margin-top: 33px;
		margin-left: 200px;
		font-size: 28px;
	}

	.manbanner h3 {
		margin-top: 15px;
		margin-left: 200px;
		font-size: 28px;
	}

	.well.printbanner {
		background: #D2A42E url(/images/print-img.png) no-repeat left top;
		height: 250px;
	}

	.printbanner h3 {
		margin-top: 50px;
		margin-left: 240px;
		font-size: 22px;
	}

	.printbanner h4 {
		margin-top: 30px;
		margin-left: 240px;
		font-size: 16px;
	}

	.well.pricebanner {
		background: #D2A42E url(/images/price-hdr.png) no-repeat left top;
		height: 250px;
	}

	.pricebanner h3 {
		margin-top: 10px;
		margin-left: 220px;
		font-size: 22px;
	}

	.pricebanner h4 {
		margin-top: 20px;
		margin-left: 220px;
		font-size: 16px;
	}

	h4.receipttitle {
		line-height: 1.3;
		font-size: 16px;
	}

	.jumbotron h1, .jumbotron .h1 {
		font-size: 35px;
		margin-bottom: 5px;
		margin-left: 140px;
	}

	#statelanding.jumbotron h1, #statelanding.jumbotron .h1 {
		font-size: 30px;
		margin-bottom: 10px;
		margin-top: 10px;
		margin-left: 0;
	}

	#inners.jumbotron h1, #inners.jumbotron .h1 {
		font-size: 24px;
		margin-bottom: 5px;
		margin-left: 0;
		margin-right: 110px;
	}

	#inners.jumbotron h5, #inners.jumbotron .h5 {
		font-size: 13px;
		margin-top: 5px;
		margin-right: 110px;
	}

	.jumbotron h2, .jumbotron .h2 {
		font-size: 20px;
		margin-top: 0;
		margin-bottom: 5px;
		margin-left: 140px;
	}

	.jumbotron h3, .jumbotron .h3 {
		font-size: 34px;
	}

	.jumbotron h4, .jumbotron .h4 {
		font-size: 14px;
		margin-top: 0;
		margin-right: 0;
		margin-left: 140px;
		text-align: left;
	}

	.homeforms {
		background-image: url(/images/bg-top.png);
		background-repeat: repeat-x;
		background-position: top;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 6px;
		margin-top: 10px;
		margin-right: 15px;
		margin-left: 15px;
		margin-bottom: 0;
	}

	#hpanel.homeforms {
		padding: 10px 10px 0 10px;
	}

	.stateforms {
		background-image: url(/images/bg-top.png);
		background-repeat: repeat-x;
		background-position: top;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 6px;
		margin-top: 10px;
		margin-right: 15px;
		margin-left: 15px;
		margin-bottom: 10px;
	}

	.lead {
		font-size: 14px;
	}

	.jumbocheck {
		margin-top: 20px;
		padding: 0;
	}

	.jumbocheck ul {
		list-style: none;
		list-style-image: none;
		font-size: 20px;
		font-weight: 700;
		padding: 0;
		margin: 0;
		background: url(/images/seal-ca.png) no-repeat right top;
		min-height: 140px;
	}

	.jumbocheck ul li {
		background: url(/images/ico-check.png) left no-repeat;
		padding-left: 25px;
	}

	h2 {
		font-size: 26px;
		margin-top: 0;
		margin-bottom: 10px;
	}

	.reviewbox {
		margin-right: 15px;
		margin-left: 15px;
	}

	#stepforms h3,
	#summforms h3,
	#frmreferral h3 {
		font-size: 22px;
	}

	#receiptform h3 {
		font-size: 22px;
	}
}

@media (max-width: 767px) {
	.nav {
		font-size: 14px;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
		color: #484848;
		font-weight: 700;
	}

	.navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover {
		color: #484848;
		font-weight: 700;
		background-color: #cccccc;
	}

	#statelanding .form.stateforms h3 span {
		font-size: 26px;
		line-height: 26px;
		display: block;
	}

	.wellsmcc {
		min-height: 50px;
		padding-top: 10px;
	}

	.wellsmgua {
		min-height: 50px;
		padding-top: 10px;
	}

	.wellsmfree {
		min-height: 50px;
		padding-top: 10px;
	}

	.well.video h4 {
		font-size: 22px;
	}

	h4.modal-title {
		font-size: 18px;
	}

	#summforms h3 small {
		display: block;
		padding-bottom: 10px;
		padding-top: 10px;
	}

	.trlarge {
		text-align: right;
	}

	.well.livechat {
		float: left;
	}

	.rightcol {
		margin-top: 0;
	}

	.breadcrumb {
		/* this one */
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.navbar-inverse {
		height: 50px;
	}

	#header-logo {
		background: url(/images/logo3.png) no-repeat 0 10px;
		width: 182px;
		height: 45px;
		display: block;
		text-indent: -9999px;
	}

	/* seal */
	.seals {
		top: 4px;
		width: 116px;
		height: 110px;
		left: 10px;
	}

	#inners.jumbotron {
		min-height: 80px;
	}

	.jumbotron h1, .jumbotron .h1 {
		font-size: 28px;
		margin-bottom: 5px;
		margin-left: 120px;
	}

	.jumbotron h2, .jumbotron .h2 {
		font-size: 16px;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: 120px;
	}

	.jumbotron h3, .jumbotron .h3 {
		font-size: 27px;
	}

	.jumbotron h4, .jumbotron .h4 {
		font-size: 11px;
		margin-top: 2px;
		margin-right: 0;
		margin-left: 120px;
		text-align: left;
	}

	.homeforms {
		background-image: url(/images/bg-top.png);
		background-repeat: repeat-x;
		background-position: top;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 6px;
		margin-top: 10px;
		margin-right: 15px;
		margin-left: 15px;
		margin-bottom: 0;
	}

	#hpanel.homeforms {
		padding: 10px 10px 0 10px;
	}

	.stateforms {
		background-image: url(/images/bg-top.png);
		background-repeat: repeat-x;
		background-position: top;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 6px;
		margin-top: 10px;
		margin-right: 15px;
		margin-left: 15px;
		margin-bottom: 10px;
	}

	.lead {
		font-size: 16px;
	}

	.jumbocheck {
		margin-top: 20px;
		padding: 0;
	}

	.jumbocheck ul {
		list-style: none;
		list-style-image: none;
		font-size: 18px;
		font-weight: 700;
		padding: 0;
		margin: 0;
		background: none;
		background-image: none;
		min-height: 120px;
	}

	.jumbocheck ul li {
		background: url(/images/ico-check.png) left no-repeat;
		padding-left: 25px;
	}

	h2 {
		font-size: 24px;
		margin-top: 0;
		margin-bottom: 10px;
	}

	.reviewbox {
		margin-right: 15px;
		margin-left: 15px;
	}

	.callus {
		font-weight: 700;
		font-size: 16px;
		color: #fff;
		float: left;
		padding-top: 5px;
		line-height: 20px;
		letter-spacing: 0;
		height: 43px;
		border-bottom: solid 1px #DDBB60;
		display: block;
		width: 100%;
	}

	.callus span {
		color: #fff;
		font-weight: 700;
	}

	.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
		border-color: #145165;
		border-top: solid 5px #145165;
		border-right: solid 1px #145165;
		border-left: solid 1px #145165;
		border-bottom: solid 1px #145165;
	}

	.navbar-collapse {
		border-color: #484848;
		background-color: #D2A42E;
		border-top: solid 1px #D2A42E;
	}

	.navbar-inverse .navbar-nav > li > a:hover {
		color: #484848;
		background-color: #ccc;
	}

	.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
		color: #145165 !important;
		background-color: transparent;
	}

	.navbar-nav.navbar-right li {
		border-right: solid 1px #D2A42E;
		border-right-color: #D2A42E !important;
	}

	#stepforms h3,
	#summforms h3,
	#frmreferral h3 {
		font-size: 22px;
	}

	#receiptform h3 {
		font-size: 22px;
	}
}

@media (min-width: 468px) and (max-width: 767px) {
	.postbox {
		height: auto;
	}

	.postbox img {
		float: left;
		max-width: 200px;
		margin-right: 5px;
	}

	.table.pricetable tbody tr td.tno {
		width: 70px;
	}

	.table.pricetable tbody tr td.tcyes {
		width: 70px;
	}

	.table.pricetable tbody .info .thdr {
		width: 70px;
	}

	#attorney.well {
		background: #D2A42E url(/images/pic-attorney3.png) left bottom no-repeat;
		padding: 15px 15px 10px 100px !important;
		font-size: 13px;
	}

	#attorney.well h2 {
		font-size: 26px;
	}

	#attorney.well h2 span {
		font-size: 22px;
	}

	#inners .container .seals.visible-xs {
		top: -6px;
		width: 116px;
		height: 110px;
		right: 0;
		left: auto;
	}

	#inners.jumbotron {
		padding: 10px 0;
	}

	#inners.jumbotron h1, #inners.jumbotron .h1 {
		font-size: 20px;
		margin-bottom: 5px;
		margin-left: 0;
		letter-spacing: 0;
		margin-right: 120px;
	}

	#inners.jumbotron h5, #inners.jumbotron .h5 {
		font-size: 13px;
		margin-top: 5px;
		margin-right: 120px;
	}

	#statelanding.jumbotron h1, #statelanding.jumbotron .h1 {
		font-size: 28px;
		margin-bottom: 10px;
		margin-top: 10px;
		margin-left: 0;
	}

	.paybanner h3 {
		margin-top: 20px;
		margin-left: 160px;
		font-size: 28px;
	}

	.manbanner h3 {
		margin-top: 20px;
		margin-left: 160px;
		font-size: 24px;
	}

	.well.printbanner {
		background: #D2A42E url(/images/print-img2.png) no-repeat left top;
		min-height: 210px;
	}

	.printbanner h3 {
		margin-top: 30px;
		margin-left: 140px;
		font-size: 20px;
	}

	.printbanner h4 {
		margin-top: 20px;
		margin-left: 140px;
		font-size: 14px;
	}

	.well.pricebanner {
		background: #D2A42E url(/images/price-hdr2.png) no-repeat left top;
		min-height: 210px;
	}

	.pricebanner h3 {
		margin-top: 30px;
		margin-left: 140px;
		font-size: 20px;
	}

	.pricebanner h4 {
		margin-top: 20px;
		margin-left: 140px;
		font-size: 14px;
	}

	h4.receipttitle {
		line-height: 1.2;
		font-size: 15px;
	}

	.btn-warning.bprint {
		float: none;
		margin: 10px auto 10px auto;
		display: block;
	}

}

@media (max-width: 467px) {
	.postbox {
		height: auto;
	}

	.postbox img {
		float: left;
		max-width: 150px;
		margin-right: 5px;
	}

	/* price table */
	.table.pricetable {
		font-size: 11px;
	}

	.table.pricetable tbody tr td.tno {
		width: 60px;
	}

	.table.pricetable tbody tr td.tcyes {
		width: 60px;
	}

	.table.pricetable tbody .info .thdr {
		width: 60px;
	}

	.table.pricetable tbody tr td {
		height: 90px !important;
		vertical-align: middle;
	}

	.table.pricetable tbody tr .info.tyes {
		background-position: 5px 36px !important;
		padding-right: 3px !important;
		padding-left: 27px !important;
	}

	.table.pricetable tbody .total .info {
		padding-top: 10px;
	}

	.table.pricetable tbody .total .tcomp {
		width: 60px;
		padding-top: 10px;
	}

	#attorney.well {
		background: #D2A42E url(/images/pic-attorney2.png) left bottom no-repeat;
		padding: 15px 15px 10px 100px !important;
		font-size: 13px;
	}

	#attorney.well h2 {
		font-size: 26px;
	}

	#attorney.well h2 span {
		font-size: 22px;
	}

	#inners .seals {
		top: -5px;
		width: 116px;
		height: 110px;
		right: 0;
		left: auto;
	}
	#inners.jumbotron {
		padding: 10px 0;
	}
	#inners.jumbotron h1, #inners.jumbotron .h1 {
		font-size: 20px;
		margin-bottom: 5px;
		letter-spacing: 0;
		margin-right: 120px !important;
		margin-left: 0;
	}

	#inners.jumbotron h5, #inners.jumbotron .h5 {
		font-size: 13px;
		margin-top: 5px;
		margin-right: 120px;
		margin-left: 0;
	}

	#statelanding.jumbotron h1, #statelanding.jumbotron .h1 {
		font-size: 24px;
		margin-bottom: 5px;
		margin-top: 5px;
		margin-left: 0;
	}

	.paybanner h3 {
		margin-top: 10px;
		margin-left: 160px;
		font-size: 24px;
	}

	.well.printbanner {
		background: #D2A42E url(/images/print-img3.png) no-repeat left top;
		min-height: 200px;
	}

	.printbanner h3 {
		margin-top: 20px;
		margin-left: 100px;
		font-size: 20px;
	}

	.printbanner h4 {
		margin-top: 40px;
		margin-left: 15px;
		font-size: 14px;
	}

	.well.pricebanner {
		background: #D2A42E url(/images/price-hdr3.png) no-repeat left top;
		min-height: 200px;
	}

	.pricebanner h3 {
		margin-top: 20px;
		margin-left: 100px;
		font-size: 20px;
	}

	.pricebanner h4 {
		margin-top: 20px;
		margin-left: 5px;
		font-size: 14px;
	}

	h4.receipttitle {
		line-height: 1.2;
		font-size: 15px;
	}

	.btn-warning.bprint {
		float: none;
		margin: 10px auto 10px auto;
		display: block;
	}
}

/*INNERS */

.breadcrumb {
	font-size: 11px;
	padding: 3px 0;
	background-color: transparent;
}

.breadcrumb > li + li:before {
	color: #ccc;
	content: "\003e";
}

.breadcrumb > .active {
	color: #777;
}

h3 span {
	color: #115062;
}

.progress-bar-info {
	background-color: #D2A42E;
}

img.statemap {
	width: 60px;
	height: 60px;
	float: left;
	margin-right: 10px;
	margin-top: 10px;
}

img#smfee.statemap {
	width: 60px;
	height: 60px;
	float: none;
	margin-right: 10px;
	margin-top: 10px;
}

.well.workbanner {
	background-color: #fff5d9;
	padding: 10px 10px 10px 10px;
	color: #115062;
	border-color: #ffeab1;
}

.workbanner h3 {
	margin-top: 0;
	margin-bottom: 5px;
	color: #115062;
}

.workbanner ul {
	list-style: none;
	list-style-image: none;
	font-size: 14px;
	padding: 0;
	margin: 0;
}

.workbanner ul li {
	background: url(/images/ico-check2.png) 90px 3px no-repeat;
	padding-left: 105px;
}

.list-group-item-heading {
	color: #FFF;
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
	color: #fff;
	background-color: #115062;
	border-color: #115062;
	cursor: default;
}

.list-group-item {
	border: 1px solid #D2A42E;
	background-color: #fff5d9;
}

a.list-group-item {
	color: #115062;
	font-weight: bold;
}

a.list-group-item.listhead {
	color: #484848;
	font-weight: bold;
	background-color: #D2A42E;
}

a.list-group-item:hover {
	color: #484848;
}

a.list-group-item.disabled:hover {
	cursor: default;
}

.list-group .list-group-item span {
	font-size: 33px;
	font-weight: 400;
	line-height: 20px;
	font-style: italic;
	font-family: Georgia, "Times New Roman", Times, serif;
	color: #D2A42E;
}

.list-group.howbig .list-group-item span {
	margin-bottom: 15px;
	margin-top: 0;
}

.list-group.howbig .list-group-item h3 {
	margin-bottom: 15px;
	margin-top: 0;
	font-size: 22px;
}

.list-group.howbig .list-group-item {
	color: #484848;
	background-color: #fff5d9;
	padding: 20px;
}

.well.caseworker, .well.tvnews {
	/* revised 11.27.2014*/
	background-color: #fff;
	background: url(/images/bg-top.png) repeat-x left top;
	font-size: 12px;
}

.well.caseworker h4, .well.tvnews h4 {
	/* revised 11.27.2014*/
	color: #115062;
	margin-top: 0;
	padding-bottom: 5px;
	display: block;
	border-bottom: solid 1px #ccc;
}

.well.caseworker img, .well.tvnews img {
	/* revised 11.27.2014*/
	width: 60px;
	height: auto;
	float: left;
	margin-right: 10px;
}

.well.tvnews img {
	/* revised 11.27.2014*/
	width: auto;
	height: auto;
	float: left;
	margin-right: 10px;
}

.tooltip.in {
	filter: alpha(opacity=100);
	opacity: 1;
}

.tooltip-inner {
	background-color: #115062;
	max-width: 250px;
}

.tooltip.top .tooltip-arrow {
	border-top-color: #115062;
}

a.tooltips {
	color: #115062;
	font-weight: 400 !important;
}

#stepforms,
#frmreferral {
	border: solid 1px #ccc;
	border-radius: 6px;
	padding: 15px;
	background: url(/images/bg-top.png) repeat-x left top;
	margin-bottom: 30px;
}

#stepforms h3,
#frmreferral h3 {
	margin-top: 0;
	margin-bottom: 10px;
	display: block;
	padding-bottom: 10px;
	border-bottom: solid 1px #ccc;
	font-weight: 400;
	letter-spacing: 0;
}

h3 .glyphicon.glyphicon-info-sign {
	font-size: 16px;
	padding-left: 5px;
	padding-right: 0;
	font-weight: 400;
}

#stepforms label,
#frmreferral label {
	font-weight: 400;
	color: #484848;
}

a.btn.btn-success {
	line-height: 2.42857143;
}

a.btn.btn-success.btn-qualify {
	line-height: 2.42857143;
	padding-right: 10px;
}

.btn-success.bnext {
	background-color: transparent;
	border: none;
	background: url(/images/bg-button-sm.png) no-repeat left 3px;
	min-width: 123px;
	min-height: 50px;
	text-align: right;
	padding-right: 40px;
	margin: 0;
}

.btn-success.bback {
	background-color: transparent;
	border: none;
	background: url(/images/bg-button-sm-back.png) no-repeat left 3px;
	min-width: 123px;
	min-height: 50px;
	padding-left: 40px;
	text-align: left;
	margin: 0;
}

.btn-success.bstart {
	background-color: transparent;
	border: none;
	background: url(/images/bg-button-md.png) no-repeat left 3px;
	min-width: 178px;
	min-height: 56px;
	text-align: right;
	padding-right: 30px;
	margin: 0;
}

#stepforms .form-group,
#frmreferral .form-group {
	margin-bottom: 20px;
}

.required {
	color: #C00;
}

.text-success {
	color: inherit;
	font-weight: 700;
	text-decoration: underline;
}

.alert {
	padding: 10px 10px 10px 30px;
	margin-bottom: 10px;
	font-size: 12px;
	background: inherit url(/images/ico-exc.png) no-repeat 5px 7px;
}

.alert-danger {
	color: #c4653b;
	background-color: #fff1eb;
	border-color: #ffe8de;
}

.linkdesktop {
	border: solid 1px #999;
	border-radius: 3px;
	padding: 1px 6px;
	margin: 10px 0;
	display: inline-block;
}

/* LIVE CHAT */
.well.livechat {
	border: none;
	padding: 0;
	min-width: 184px;
	height: 83px;
	margin: 0;
}

.well.livechat.online {
	background: none;
}

.well.livechat.offline {
	background: none;
}

.well.livechat a {
	display: block;
	min-width: 184px;
	height: 83px;
	text-align: center;
}

/* TESTIMONIAL */
blockquote {
	padding: 0 0;
	font-size: 13px;
	border-left: none;
	margin: 0 0 15px 0;
}

blockquote footer, blockquote small, blockquote .small {
	display: block;
	font-size: 90%;
	line-height: 1.42857143;
	color: #777;
	font-weight: 700;
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
	content: none;
}

blockquote.incopy {
	padding: 0 0;
	margin: 0 0 1em 2.5em;
	font-size: 14px;
	border-left: none;
}

.label {
	display: inline;
	padding: .3em .6em .3em;
	font-size: 55%;
}

h3 .label.label-primary a, h3 .label.label-primary a .glyphicon.glyphicon-pencil {
	color: #fff;
}

.form-group.c1 {
	font-weight: 700;
}

.form-group.c2 {
	font-weight: 700;
	color: #115062;
}

.label-primary {
	background-color: #115062;
}

#summforms {
	border: solid 3px #115062;
	border-radius: 6px;
	padding: 15px;
	background-color: #f5f5f5;
	background-image: none;
	margin-bottom: 5px;
	font-weight: 400;
	color: #484848;
	font-size: 13px;
}

#summforms h3 {
	margin-top: 0;
	margin-bottom: 0;
	display: block;
	padding-bottom: 10px;
	padding-top: 0;
	font-weight: 700;
	letter-spacing: 0;
	color: #115062;
}

#summforms h3 small {
	font-weight: 700;
	letter-spacing: 0;
	color: #484848;
}

#summforms .row .form-group .checkbox {
	margin-top: 0;
	margin-bottom: 0;
}

#summforms .row .form-group {
	margin-top: 0;
	margin-bottom: 0;
}

#summforms .row {
	margin-bottom: 12px;
}

.summformstitle {
	border-bottom: solid 1px #ccc;
}

.btn-success.blarge {
	font-size: 34px;
	background: url(/images/bg-button-lg.png) no-repeat left 3px;
	min-width: 230px;
	min-height: 76px;
	text-align: left;
	padding-left: 35px;
	float: none;
	margin: 0 auto;
	display: block;
}

.well.paybanner {
	padding: 0 10px 0 0;
	border-color: #D2A42E;
	background: #D2A42E url(/images/paytop-pic.png) no-repeat left top;
	height: 100px;
}

.well.manbanner {
	padding: 0 10px 0 0;
	border-color: #D2A42E;
	background: #D2A42E url(/images/mantop-pic.png) no-repeat left top;
	height: 100px;
	margin-top: 15px;
	margin-bottom: 0;
}

.paybanner h3 {
	color: #484848;
}

.well.printbanner {
	padding: 0 15px 0 0;
	border-color: #D2A42E;
	margin-bottom: 0;
}

.printbanner h3 {
	color: #484848;
}

.printbanner h4 {
	color: #fff;
	letter-spacing: 0;
}

.printbanner h4 span {
	font-weight: 700;
	color: #484848;
	text-decoration: underline;
}

.pricebanner h3 {
	color: #484848;
}

.pricebanner h4 {
	color: #fff;
	letter-spacing: 0;
}

.pricebanner h4 span {
	font-weight: 700;
	color: #484848;
	text-decoration: underline;
}

.stardiv {
	display: inline-block;
	float: right;
}

/* receipt form */
#receiptform {
	border: solid 1px #ccc;
	border-radius: 6px;
	padding: 15px;
	background-color: #fff;
	background-image: none;
	margin-bottom: 5px;
	font-weight: 400;
	font-size: 13px;
}

#receiptform h3 {
	margin-top: 0;
	margin-bottom: 0;
	display: block;
	text-align: center;
	padding-bottom: 10px;
	padding-top: 0;
	font-weight: 700;
	letter-spacing: 0;
}

#receiptform h3 small {
	font-weight: 700;
	letter-spacing: 0;
	color: #484848;
}

#receiptform .row {
	margin-bottom: 12px;
}

.receiptformtitle {
	border-bottom: dotted 1px #ccc;
}

td.active, tr.warning, tr.active {
	font-weight: 700;
}

td.nowrap {
	min-width: 85px;
}

.btn-warning {
	background-color: #115062;
	border-color: #115062;
	font-weight: 700;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active {
	background-color: #D3A42F;
	border-color: #D3A42F;
}

.btn-info {
	background-color: #D3A42F;
	border-color: #ad8726;
	font-weight: 700;
}

.btn-start:hover {
	background-color: #ad8726;
}

.btn-info:hover, .btn-info.btn-lg:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
	background-color: #115062;
	border-color: #115062;
}

.btn-info.mcenter {
	background-color: #115062;
	border-color: #115062;
	font-weight: 700;
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
	background-color: #D3A42F;
	border-color: #ad8726;
}

.btn-info span {
	font-size: 14px;
	display: block;
	margin-top: -2px;
}

.btn-default.bcancel {
	background-color: #999;
	border-color: #666;
	font-weight: 700;
	color: #fff;
}

.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
	background-color: #666;
	border-color: #666;
}

.btn-success.binter {
	font-size: 34px;
	background: url(/images/bg-button-inter.png) no-repeat left 3px;
	min-width: 346px;
	min-height: 86px;
	text-align: left;
	padding-left: 35px;
	float: none;
	margin: 0 auto;
	display: block;
}

/* popups */

.modal-footer {
	padding: 15px;
	text-align: center;
	border-top: 1px solid #e5e5e5;
}

.modal-header {
	min-height: 16.42857143px;
	padding: 15px;
	border-bottom: 1px solid #ccc;
}

h4.modal-title {
	color: #115062;
}

.modal-content {
	background-color: #fff;
	border: 2px solid #115062;
	border: 2px solid rgba(17, 80, 98, 1);
	color: #333;
}

.drop-shadow {
	-webkit-box-shadow: 0 0 5px 2px rgba(0, 0, 0, .2);
	box-shadow: 0 0 5px 2px rgba(0, 0, 0, .2);
}

#attorney.well.drop-shadow {
	padding-left: 0;
	padding-right: 0;
}

#attorney.well {
	border: solid 1px #D2A42E;
	background-color: #D2A42E;
	color: #484848;
	margin-bottom: 0;
}

#attorney.well h2 {
	color: #fff;
	margin-top: 0;
	margin-bottom: 5px;
	line-height: 24px;
	border: solid 1px #115062;
	background-color: #115062;
	padding: 5px;
	border-radius: 3px;
}

#attorney.well h2 span {
	color: #fff;
	margin-top: 0;
}

/* Site Inners*/

h3.pagetitle {
	margin-top: 5px;
	padding-bottom: 5px;
	border-bottom: solid 1px #ccc;
}

/*
START - Modal Fixes
*/

.jumbotron .modal-body p {
	margin: 0 0 10px;
	font-size: 14px;
	font-weight: normal;
	text-align: left;
}

.jumbotron .modal-body h3.pagetitle {
	text-align: left;
}

.jumbotron h4.modal-title,
.jumbotron .h4.modal-title {
	font-size: 18px;
	margin: 0;
	text-align: left;
}

/*
END - Modal Fixes
*/

h3.pagesubtitle {
	margin-top: 20px;
	padding-bottom: 5px;
	border-bottom: dotted 1px #ccc;
	font-weight: 400;
}

.whychoose {
	border: solid 1px #115062;
	background-color: #115062;
	color: #fff;
	padding: 5px 12px 10px 15px;
	border-radius: 6px;
	margin: 0 0 20px 0;
	background: #115062 url(/images/bg-topshadow.png) repeat-x left top;
}

.whychoose.list-unstyled li {
	background: url(/images/ico-check2.png) no-repeat left 6px;
	padding-left: 15px;
	padding-top: 3px;
	padding-bottom: 3px;
}

.promise ul {
	list-style: none;
	list-style-image: none;
	list-style-type: none;
}

.promise li {
	list-style: none;
	background: url(/images/ico-check2.png) no-repeat left 10px;
	padding-left: 20px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.whychoose.list-unstyled .whyheader h4 {
	margin-top: 10px;
	margin-bottom: 5px;
	padding-top: 0;
	padding-bottom: 10px;
	color: #D2A42E;
	line-height: 22px;
	border-bottom: dotted 1px #fff5d9;
}

.whychoose.list-unstyled .whyheader {
	background: none;
	background-image: none;
	padding-left: 0;
}

.whychoose.list-unstyled .whyheader h4 span {
	font-size: 26px;
	display: block;
	padding-bottom: 5px;
	color: #fff5d9;
}

/* help page */

ul#helppage li.list-group-item {
	border: solid 1px #ccc;
	background-color: #fff;
}

ul#helppage li.list-group-item .listhead {
	color: #115062;
	font-weight: bold;
	background-color: transparent;
	cursor: pointer;
}

ul#helppage li.list-group-item #listcontent {
	margin-top: 5px;
}

/* sidebar video */
.well.video {
	min-height: 20px;
	padding: 15px;
	margin-bottom: 20px;
	background-color: #D2A42E;
	border: 1px solid #D2A42E;
	border-radius: 6px;
	-webkit-box-shadow: none;
	box-shadow: none;
	background: #D2A42E url(/images/bg-topshadow-wht.png) repeat-x left top;
}

.well.video a img {
	cursor: pointer;
}

.well.video h4 {
	color: #FFF;
	margin-top: 0;
}

.well.team {
	min-height: 200px;
}

.well.team img {
	width: 130px;
	height: auto;
	float: left;
	margin-right: 10px;
	margin-bottom: 5px;
}

.teamcw img.img-rounded {
	width: 80px;
	height: auto;
	margin-bottom: 5px;
}

.well.team h5 {
	font-size: 16px;
	margin-bottom: 15px;
	margin-top: 0;
	letter-spacing: -1px;
}

.well.guarantee {
	background-color: #c6d5d9;
	border: dotted 3px #fff;
	background-image: url(/images/bg-gua-border.png);
	background-repeat: no-repeat;
	background-position: left top;
	text-align: center;
	padding: 40px;
	color: #484848;
}

.well.guarantee h1 {
	font-size: 28px;
	font-weight: 400;
	line-height: 28px;
	font-style: italic;
	font-family: Georgia, "Times New Roman", Times, serif;
	color: #484848;
	margin-bottom: 20px;
	margin-top: 10px;
}

/* Panels */
.panel .panel-heading {
	font-size: 16px;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: 0;
	text-transform: capitalize;
	text-align: center;
}

.panel .panel-heading span {
	font-size: 28px;
	font-weight: 700;
	line-height: 28px;
	letter-spacing: -1px;
	display: block;
}

/* Panel Danger Box */
.panel-danger {
	border-color: #666;
	background-color: #efefef;
	text-align: center;
	color: #333;
}

.panel.panel-danger > .panel-heading {
	color: #fff5d9;
	background-color: #b30000;
	border-color: #ccc;
	background: #b30000 url(/images/bg-topshadow.png) repeat-x left top;
}

/* Homepage Panel Danger Box */
.homeforms .panel-danger {
	border: none;
	background-color: transparent;
	text-align: left;
	color: #333;
	font-size: 14px;
	padding: 0;
	margin: 10px;
}

.homeforms .panel-danger .panel-body {
	font-size: 14px;
	padding: 10px 0 0 0;
}

.homeforms .panel-danger .panel-body p {
	font-size: 14px;
}

.panel-body p b {
	color: #115062;
}

.homeforms .panel.panel-danger > .panel-heading {
	color: #b30000;
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: dotted 1px #b30000;
	background-color: transparent;
	background: none;
	text-align: left;
	padding: 0 0 5px 0;
}

.homeforms .panel .panel-heading {
	font-size: 18px;
	font-weight: 800;
	line-height: 28px;
	letter-spacing: -1px;
	text-transform: capitalize;
	text-align: center;
}

.homeforms .panel .panel-heading span {
	font-size: 30px;
	font-weight: 800;
	line-height: 28px;
	letter-spacing: -1px;
	display: block;
}

.homeforms .panel.panel-danger .btn.btn-danger {
	color: #fff5d9;
	margin-bottom: 20px;
}

/* Panel Success Box */
.panel-success {
	border-color: #115062;
	background-color: #efefef;
	text-align: left;
	color: #333;
}

.panel.panel-success > .panel-heading {
	color: #fff5d9;
	background-color: #115062;
	border-color: #ccc;
	background: #115062 url(/images/bg-topshadow.png) repeat-x left top;
}

.panel.panel-success .panel-body p {
	font-size: 13px;
	margin: 0 0 10px 0;
	border-bottom: solid 1px #ccc;
	padding: 0 0 10px 65px;
	min-height: 58px;
	background-position: 5px 5px;
	background-repeat: no-repeat;
	background-size: 35px;
}

.btn-warning .badge {
	color: #f0ad4e;
	background-color: #fff;
}

.btn-danger {
	color: #fff5d9;
	background-color: #b30000;
	border-color: #8b0101;
	font-weight: 700;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
	color: #fff;
	background-color: #115062;
	border-color: #115062;
}

/* Price table */
.pricing-table tbody tr td .glyphicon-ok {
	color: #9ACD46;
	font-size: 19px;
	padding-right: 15px;
}

.pricing-table tr td:last-child {
	background-color: #114F63;
}

.pricing-table .col-1 {
	width: 32% !important;
}

.pricing-table tbody tr td,
.pricing-table tbody tr th {
	padding: 15px 0;
}

.pricing-table tbody tr th {
	font-size: 15px;
	font-weight: bold;
}

.pricing-table tbody tr th:nth-child(n + 1) {
	text-align: center;
}

.pricing-table tbody tr td:first-child,
.pricing-table tbody tr th:first-child{
	font-weight: bold;
	padding-left: 20px;
}

.pricing-table tbody tr td:last-child {
	font-weight: bold;
	text-align: center;
}

.pricing-table tbody tr td.col-2,
.pricing-table tbody tr td.col-2 span {
	text-align: center;
	margin: 0 auto;
}

.pricetable {
	font-size: 13px;
}

.table.pricetable > tbody > tr.info > td {
	background-color: #115062;
	color: #fff;
	font-weight: 700;
}

.table.pricetable tbody tr .info.tyes {
	background-color: #115062;
	background-image: url(/images/ico-yes.png);
	color: #fff;
	background-position: 6px 7px;
	background-repeat: no-repeat;
	padding-left: 30px;
	width: 70px;
}

.table.pricetable tbody tr td.tno {
	text-align: center;
	background-image: url(/images/ico-no.jpg);
	background-position: center;
	background-repeat: no-repeat;
}

.table.pricetable tbody tr td.tcyes {
	text-align: center;
}

.table.pricetable tbody .total {
	color: #484848;
}

.table.pricetable tbody .total .info {
	color: #D2A42E;
	font-weight: 700;
	text-align: center;
	background-color: #115062;
	font-size: 16px;
}

.table.pricetable tbody .total .tcomp {
	padding-top: 10px;
	text-align: center;
}

.table.pricetable tbody .info .thdr {
	text-align: center;
	vertical-align: middle;
}

.btn-primary {
	color: #fff;
	background-color: #115062;
	border-color: #115062;
	font-weight: 700;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
	color: #115062;
	background-color: #fff;
	border-color: #115062;
}

.wellsmcc {
	font-size: 13px;
	color: #484848;
}

.wellsmgua {
	padding-left: 80px;
	background: #D2A42E url(/images/bg-topshadow-wht.png) repeat-x left top;
	color: #484848;
	font-size: 13px;
	font-weight: 700;
	border-color: #D2A42E;
}

.wellsmfree {
	background-color: #115062;
	color: #fff;
	font-size: 13px;
	border-color: #115062;
	padding-left: 80px;
	margin-bottom: 0;
}

.wellsmfree span {
	font-weight: 700;
	text-decoration: underline;
}

.statelist ul {
	list-style: none;
	list-style-image: none;
	font-size: 12px;
	line-height: 12px;
	font-weight: 700;
	padding: 0;
	margin: 0;
}

.statelist ul li {
	background: url(/images/ico-check3.png) left no-repeat;
	padding-left: 14px;
	margin: 10px 0;
}

.statelist ul li a {
	color: #115062;
}

.backbuts {
	float: right;
	display: inline-block;
	margin: 0 0 20px 0;
	padding: 0;
	font-size: 12px;
}

.statedet {
	margin-bottom: 0;
	margin-top: 0;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
	color: #fff;
	background-color: #D2A42E;
	border-color: #D2A42E;
}

.pagination {
	margin: 0 0 20px 0;
}

.statelandimgv {
	background-color: #fff;
	border: solid 1px #D2A42E;
	border-radius: 6px;
	padding: 3px 0;
	margin-left: 15px;
	margin-right: 15px;
}

.contentbox {
	background: #fff url(/images/bg-top.png) top left repeat-x;
	padding: 15px;
	border-radius: 6px;
	border: solid 1px #ccc;
	margin-top: 20px;
	margin-bottom: 10px;
	margin-left: 0;
	margin-right: 0;
}

.contentbox h3 {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-bottom: 10px;
	border-bottom: dotted 1px #ccc;
}

#cta.well {
	margin-top: 20px;
	background-color: #D2A42E;
	border: 1px solid #D2A42E;
	background: #D2A42E url(/images/bg-topshadow-wht.png) repeat-x left top;
}

#cta.well h3 {
	margin: 0;
	text-align: center;
	color: #FFF;
}

#cta.well h3 a {
	color: #FFF;
	text-decoration: underline;
}

#cta.well h3 a:hover {
	color: #FFEF6B;
	text-decoration: underline;
}

.date {
	font-size: 11px;
	color: #666;
	display: block;
}

h4.posttitle {
	margin-top: 0;
}

h4.posttitle a {
	color: #115062;
}

h4.posttitle a:hover {
	color: #115062;
	text-decoration: underline;
}

.img-thumbnail {
	max-height: 140px;
}

.postbox {
	overflow: auto;
	float: left;
	padding-bottom: 10px;
	margin-bottom: 10px;
	font-size: 13px;
}

.bottomstates {
	font-size: 12px;
	text-align: left;
	border: none;
}

.bottomstates h4 {
	margin-bottom: 10px;
	margin-top: 0;
}

.footerscss ul.list-unstyled {
	margin-top: 0;
	margin-bottom: 0;
}

.navbar-inverse .navbar-nav > li > a:focus {
	color: #484848;
}

.form.divorce-price {
	input, select {
		padding: 3px;
	}
}

.row.compact {

	.form-group:not(:first-child) {
		padding-left: 0 !important;
		input, select {
			.border-left-radius(0);
		}
	}

	.form-group:not(:last-child) {
		padding-right: 0 !important;
		input, select {
			.border-right-radius(0);
			border-right: 0;
		}
	}

}

.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
	color: #ffffff;
	background-color: #fff;
	border-color: #ccc;
}

/*
* Payment Page Styles
*/

.well.well-coupon-code {

	margin-top: 15px;
	padding: 8px 5px 0 5px;

	label {
		font-size: 12px;
		margin-top: 7px;
		text-align: right;
	}

	.col-xs-3 {
		padding-left: 0;
	}

	.btn-coupon-code {
		padding: 4px 5px;
	}

	#GiftCardBlockErrDiv, #GiftCardBlockDiv {
		text-align: center;
		padding: 0;
		margin: 0;
	}

}

/*
* Fixed list-head style
*/

a.list-group-item#listhead {
	margin-top: 30px;
	color: #484848;
	font-weight: bold;
	background-color: #D2A42E;
}
